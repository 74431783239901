import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squats 5×2\\@85% 1RM`}</p>
    <p>{`Single Leg Dumbbell RDL’s 5×4/leg (using 2 DB’s)`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 amrap:`}</p>
    <p>{`7-Full Snatch (95/65)(RX+115/75)`}</p>
    <p>{`14-T2B`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      